import React, { Component } from "react";
import { Button, Typography, Row, Col, Avatar, Card } from "antd";
const background = require("../images/email-pattern.png");
const image = require("../images/doggy.jpg");
const { Title } = Typography;
class HomePage extends Component {
  state = {
    name: "Jack Lafiandra"
  };
  render() {
    const width = window.innerWidth;
    return (
      <div>
        <div
          style={{
            background: "url(" + background + ")",
            backgroundSize: "20%"
          }}>
          <Row
            style={{
              paddingTop: "40px"
            }}
            type="flex"
            justify="center"
            align="middle">
            <Avatar src={image} size={250} icon="user" />
          </Row>
          <Row justify="center" type="flex">
            <Title level={2}>{this.state.name}</Title>
          </Row>
          <Row style={{ paddingBottom: "10px" }} justify="center" type="flex">
            <div style={{ paddingRight: "10px" }}>
              <Button
                onClick={e => {
                  window.location.href = "https://www.github.com/jlafiandra6";
                }}
                shape="circle"
                icon="github"
                size="large"
              />
            </div>
            <Button
              onClick={e => {
                window.location.href =
                  "https://www.linkedin.com/in/jonathanlafiandra/";
              }}
              size="large"
              shape="circle"
              icon="linkedin"
            />
          </Row>
        </div>

        <div style={{ paddingTop: "20px" }}>
          <Row justify="center" type="flex">
            <Col
              style={{
                width: width <= 500 ? "90%" : "70%",
                height: width <= 500 ? "75%" : "60%"
              }}>
              <Card
                title={
                  <div
                    style={{
                      textAlign: "center"
                    }}>
                    About Me
                  </div>
                }>
                <p style={{ fontSize: 20 }}>
                  Hi there. I am a senior at Georgia Tech currently studying
                  Computer Science. I made this site in order to practice react
                  as well as to put all of my resume on one webpage if I can't
                  find everything on my resume. My favorite topic to learn about
                  besides Compuer Science is the NBA as it has a wealth of
                  statistics and I'm a huge fan of the Atlanta Hawks.
                  <br />
                  <br /> I consider myself a full-stack engineer, and my
                  favorite things about being a sotware engineer is that once
                  you understand a topic well, you can spin up amazing
                  applications. I'm a big proponent of cloud infrastructure
                  having worked with both a datacenter as well as cloud, and I
                  think that cloud is sometimes feared because it is
                  misunderstood. One thing that I think CS majors should spend
                  more time on is learning the architecture of a computer as it
                  provides key insight into understanding the history of
                  computers and what exactly a computer is doing underneath the
                  layers of abstraction. We need to better appreciate the how
                  and why in order to justify the Science in our name.
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default HomePage;
