import React, { Component } from "react";
import { Tabs, Row, Avatar, Col } from "antd";

import { Card } from "antd";
import { List } from "antd";
const { TabPane } = Tabs;
const { Meta } = Card;
const imageC1 = require("../images/c1.jpg");
const imageUP = require("../images/up.png");
const imageID = require("../images/id.png");
const imageSH = require("../images/shumate.jpeg");
const imageGT = require("../images/gt.png");
const languages = [
  {
    title: "Javascript"
  },
  {
    title: "Python"
  },
  {
    title: "C/C++"
  },
  {
    title: "Java"
  },
  {
    title: "R"
  }
];

const frameworks = [
  {
    title: "Backend:",
    description: "Express, Spring Boot, Flask"
  },
  {
    title: "Frontend:",
    description: "React, AngularJS, Antd, Bootstrap"
  },
  {
    title: "Databases:",
    description: "OracleDB, MongoDB, DynamoDB, mySQL"
  }
];

const tools = [
  {
    title: "General:",
    description:
      "Git, Linux/Unix OS, Socket.io, Microsoft Office, Object-Oriented Programming, CSS/HTML, Websockets, VHDL, Verilog"
  },
  {
    title: "Machine Learning:",
    description: "Sci-kit Learn, Sci-kit Image, Pickle"
  }
];

class ExperiencePage extends Component {
  state = {};

  render() {
    const width = window.innerWidth;
    return (
      <Tabs style={{ textAlign: "center" }} defaultActiveKey="1">
        <TabPane tab="Work Experience" key="1">
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageC1}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>Capital One TIP - Summer 2019</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      At Capital One I worked in a team of seven interns to
                      build a full-stack CTF application by utilizing socket.io.
                      We used the MERN tech stack and a scrum approach to create
                      the project. To make the application respond to realtime
                      events from competitors, we used websockets.
                    </p>
                  </Row>
                  <br />
                  <Row style={{ paddingBottom: "10px" }}>
                    <h4>
                      Tools used: MongoDB, NodeJS, React, Express, Jira, Github,
                      Jenkins, AWS(EC2 and S3), Axios, Socket.io
                    </h4>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageUP}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>Union Pacific Software Intern - Summer 2018</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      At Union Pacific I created an infrastructure notification
                      application which would alert relevant people whenever a
                      server or application was being taken down for
                      maintenance.
                    </p>
                  </Row>
                  <br />
                  <Row style={{ paddingBottom: "10px" }}>
                    <h4>
                      Tools used: OracleDB, Java Spring Boot, AngularJS, Github,
                      JAXB
                    </h4>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageGT}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>ECE 2031 Teaching Assistant - Spring & Fall 2018</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      Here I taught in a Digital Design Lab. DDL is a place
                      where we teach engineers about FPGAs, VHDL, and
                      communication.
                    </p>
                  </Row>
                  <br />
                  <Row style={{ paddingBottom: "10px" }}>
                    <h4>Tools used: FPGA, Verilog</h4>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageSH}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>Shumate Mechanical IT Intern - Summer 2016</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      At Shumate I learned the beginnings of webdevelopment and
                      the importance of documentation. I built computers,
                      maintained and touched up websites, and helped with any IT
                      tasks.
                    </p>
                  </Row>
                  <br />
                  <Row style={{ paddingBottom: "10px" }}>
                    <h4>Tools used: HTML, CSS, ITGlue</h4>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageID}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>ID Tech Camp Counselor - Summer 2015</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      At ID Tech Camp I taught middle schoolers the beginnings
                      of object oriented programming in Java through modding
                      Minecraft.
                    </p>
                  </Row>
                  <br />
                  <Row style={{ paddingBottom: "10px" }}>
                    <h4>Tools used: Java</h4>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
        </TabPane>
        <TabPane tab="Research" key="2">
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageGT}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>
                      Undergraduate Research - CRNCH Georgia Tech - Fall 2018
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      I researched with Professor Tom Conte to learn about
                      Advanced Computer Hierachies. I specifically looked into
                      FPGA acceleration of Kernel Algorithms to speed up machine
                      learning with a focus in the area of Intrusion Detection
                      Systems.
                    </p>
                  </Row>
                  <br />
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageGT}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>Undergraduate Research - GT Lorraine - Spring 2017</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      I worked in a team of three undegraduates to program a
                      robot to use facial and audio recognition and then perform
                      actions. I specifically wrote a python script using a
                      state machine to conduct the robot as well as wrote
                      scripts to move the arm.
                    </p>
                  </Row>
                  <br />
                </Col>
              </Row>
            </Card>
          </Row>
        </TabPane>
        <TabPane tab="Projects" key="3">
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageC1}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>
                      Capital One Carbon Hackathon - 3rd Place Finish - Summer
                      2018
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      My team and I attempted to reinvent the ATM to eliminate
                      spoofers. I built an SVM model on credit cards to take the
                      image submitted when applying for the card and recognize
                      what the card might look like after a few years of wear
                      and tear in order to reduce spoofers cloning the magnetic
                      stripe. On top of this, we added facial recognition using
                      Microsoft Azure to link a person to their card further.
                    </p>
                  </Row>
                  <br />
                  <h4>
                    Tools used: Python, Scikit Learn/Image, pickle, Tkinter,
                    Github, Microsoft Azure, Flask
                  </h4>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageGT}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>HackGT - Stocker.io - Fall 2017</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      We built a website that would display stocks for a certain
                      company, and then when you click on a certain point on the
                      graph, it would look up articles written by news outlets
                      that were written around that time. This website is no
                      longer up, but I think it's a good idea and will probably
                      revisit it at some point.
                    </p>
                  </Row>
                  <br />
                  <h4>
                    Tools used: Jquery, Javascript, Flask, Python, Bootstrap,
                    Ajax
                  </h4>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Row>
                <Col span={8}>
                  <img
                    src={imageGT}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
                <Col span={16}>
                  <Row>
                    <h1>This Website</h1>
                  </Row>
                  <br />
                  <Row>
                    <p span={24}>
                      I built this website to put any analysis and misc. ideas I
                      have.
                    </p>
                  </Row>
                  <br />
                  <h4>Tools used: React, antd, AWS</h4>
                </Col>
              </Row>
            </Card>
          </Row>
        </TabPane>
        <TabPane tab="Toolkit" key="4">
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Meta title="Programming Languages" />
              <List
                itemLayout="horizontal"
                dataSource={languages}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          <h4>{item.title}</h4> <p>{item.description}</p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Row>
          <br />
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Meta title="Frameworks, Well-known libraries, and Databases" />
              <List
                itemLayout="horizontal"
                dataSource={frameworks}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          <h4>{item.title}</h4> <p>{item.description}</p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Row>
          <br />
          <Row justify="center" type="flex">
            <Card
              style={{
                width: width <= 500 ? "95%" : "60%",
                height: width <= 500 ? "75%" : "25%"
              }}>
              <Meta title="Other Useful Tools" />
              <List
                itemLayout="horizontal"
                dataSource={tools}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          <h4>{item.title}</h4> <p>{item.description}</p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

export default ExperiencePage;
