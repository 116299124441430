import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import HomePage from "./Components/homePage";
import ExperiencePage from "./Components/ExperiencePage";
const background = require("./images/email-pattern.png");
const { Content, Sider } = Layout;
class Main extends Component {
  state = {
    collapsed: true,
    selectedKey: "1"
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width="5%"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}>
          <div className="logo" />
          <Menu
            theme="dark"
            onClick={this.updateKey}
            defaultSelectedKeys={["1"]}
            mode="inline">
            <Menu.Item key="1">
              <Icon type="home" />
              <span>Home Page</span>
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="desktop" />
              <span>Work Experience</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content
            style={{
              background: "url(" + background + ")",
              backgroundSize: "20%"
            }}>
            <div>{this.getContent()}</div>
          </Content>
        </Layout>
      </Layout>
    );
  }
  updateKey = e => {
    this.setState({ selectedKey: e.key });
  };
  getContent = () => {
    if (this.state.selectedKey === "1") {
      return <HomePage />;
    } else if (this.state.selectedKey === "2") {
      return <ExperiencePage />;
    }
  };
}
export default Main;
